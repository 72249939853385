<template>
  <div id="app">
    <HeaderComponent />
    <router-view />
    <FooterComponent />
    <ContactSupportComponent v-if="hideContactSupport" />
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { HeaderComponent, FooterComponent } from "./components";
import ContactSupportComponent from "./components/ContactSupport/ContactSupportComponent.vue";
import { watchEffect } from "vue";

export default {
  components: {
    HeaderComponent,
    FooterComponent,
    ContactSupportComponent,
  },
  data() {
    return {
      hideContactSupport: true,
      loadingStartExam: false,
    };
  },
  mounted() {
    watchEffect(() => {
      if (
        this.$route.name === "examQuestions" ||
        window.location.pathname.includes("exam-questions")
      ) {
        this.hideContactSupport = false;
      } else {
        this.hideContactSupport = true;
      }
    });
    let script = document.createElement("script");
    script.setAttribute("src", "https://www.youtube.com/iframe_api");
    script.id = "youtube-iframe-api";
    if (!document.querySelector("#youtube-iframe-api"))
      document.body.appendChild(script);
  },
};
</script>

<style>
@import "./assets/fonts/fonts.css";
:root {
  --gray-bg-color: #f5f5f0;
}
body {
  direction: rtl;
  font-family: "Cairo";
  overflow-x: hidden;
  background: var(--gray-bg-color);
  position: relative;
}
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

input:focus::placeholder {
  color: transparent !important;
}
/* body {
  direction: rtl;
  font-family: "globalFont", sans-serif;
  overflow-x: hidden;
  position: relative;
  background-color: #f5f5f5;
  color: #1e1e1e;
} */
body.dark {
  background-color: #0c0c0c;
  /* transition: all 0.3s ease-in-out; */
  position: relative;
}
.lg-hidden {
  display: none;
}
.input {
  width: 100%;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  border-bottom: 1.5px solid #1e1e1e;
  margin-bottom: 30px;
  /* padding: 5px 0; */
  position: relative;
}
.input input {
  all: unset;
  width: 90%;
  padding: 10px 0;
  color: #1e1e1e;
}
body.dark .input input {
  color: #dad2c5;
}
.clear-input {
  color: #919191;
  cursor: pointer;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
/* Swiper */
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50% !important;
  transform: translateY(-50%);
  width: 73px !important;
  height: 128px !important;
  margin-top: 0 !important;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff !important;
  background-color: #00000080 !important;
  transition: all 0.5s ease-in-out !important;
}
.swiper-button-prev:hover,
.swiper-button-next:hover {
  background-color: #000000 !important;
  transition: all 0.5s ease-in-out !important;
}
.swiper-button-prev {
  right: 0 !important;
  border-radius: 20px 0 0 20px !important;
}
.swiper-rtl .swiper-button-prev {
  right: 0 !important;
}
.swiper-button-next {
  left: 0 !important;
  border-radius: 0 20px 20px 0 !important;
}
/* Dark Mode */
body.dark .input {
  border-color: #ffffff50;
}
body.dark .swal2-popup.swal2-modal {
  background-color: #1e1e1e;
  color: #fff;
}

.el-select__selected-item.el-select__placeholder {
  display: flex;
  color: #000;
}
body.dark .el-select__selected-item.el-select__placeholder {
  color: #fff;
}
body.dark .el-select__wrapper,
body.dark .el-input__wrapper {
  background-color: #1e1c1c !important;
}
body.dark .el-input--large .el-input__inner {
  color: #fff;
}
/* start search box */
.search-box {
  padding: 20px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-bottom: 2rem;
  border-radius: 8px;
}
body.dark .search-box {
  background-color: #111111;
}
.search-box select {
  outline: none !important;
  box-shadow: none !important;
}
body.dark .search-box input,
body.dark .search-box select {
  background-color: #1e1e1e;
  color: #fff;
}
body.dark .search-box select {
  border-color: #ffffff50;
}

.search-box .input {
  all: unset;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  display: flex;
  align-items: center;
  height: 100%;
}

.search-box .input input {
  padding: 0.5rem;
  width: calc(90% - 0.5rem);
}

.search-box .input button {
  all: unset;
  padding: 0.5rem;
  width: calc(10% - 0.5rem);
  height: 100%;
  text-align: center;
  background-color: #2ecc71;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.search-box .filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.search-box .filter .selects {
  width: calc(100% / 4);
}

.cancel-filter {
  all: unset;
  padding: 5px;
  width: 100%;
  text-align: center;
  color: #fff;
  background-color: #e74c3c;
  margin-top: 1rem;
  cursor: pointer;
  border-radius: 8px;
}
.submit-btn {
  all: unset;
  background-color: #0099ff;
  color: #fff;
  text-align: center;
  padding: 0.8rem 0;
  border-radius: 0.5rem;
  cursor: pointer;
  display: block;
  width: 100%;
}
.loading-placeholder {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff95;
  font-size: 1.5rem;
  font-weight: 700;
  z-index: 30;
}
.el-select-dropdown__item {
  color: #00000099 !important;
  font-size: 1.2rem !important;
  font-weight: 700 !important;
}
.el-select-dropdown__item.is-selected {
  background-color: #0099ff !important;
  color: #fff !important;
}
.el-select__placeholder {
  color: #00000099;
  font-size: 1.2rem;
  font-weight: 700;
}
.el-textarea__inner {
  font-size: 1.1rem !important;
  font-weight: 700 !important;
  color: #000000 !important;
  resize: none !important;
  border-radius: 0.75rem !important;
  padding: 1rem !important;
  min-height: 150px !important;
}
.el-input__inner {
  font-size: 1.1rem !important;
  font-weight: 700 !important;
  color: #000000 !important;
  border-radius: 0.75rem !important;
}
.el-input__inner.el-input__inner__placeholder {
  color: #00000099 !important;
}
.el-radio__inner::after {
  width: 5px !important;
  height: 5px !important;
}
body.dark .el-input__inner {
  color: #ffffff !important;
}
body.dark .el-textarea__inner {
  color: #ffffff !important;
}
body.dark .el-textarea__inner {
  color: #ffffff !important;
}
body.dark .el-input__inner.el-input__inner__placeholder,
body.dark .el-select__placeholder,
body.dark .el-select-dropdown__item {
  color: #ffffff99 !important;
}
body.dark .el-select-dropdown__item.is-hovering {
  background-color: #1e1e1e;
}
body.dark .el-popper.is-light,
.el-popper.is-light > .el-popper__arrow:before {
  background: #111111;
  border: 1px solid #111111;
}
body.dark .el-popper.is-light,
body.dark .el-popper.is-light > .el-popper__arrow:before {
  background: #111111 !important;
  border: 1px solid #111111 !important;
  border-bottom-color: transparent !important;
  border-right-color: transparent !important;
}
body.dark .el-select-dropdown__wrap {
  background-color: #111111;
}
body.dark .el-select-dropdown__item {
  color: #fff;
}
.swal2-container {
  z-index: 1000000;
}
.image-modal-question {
  overflow: auto !important;
  /* padding-right: 0 !important; */
  /* padding-left: 1rem !important; */
  max-width: 95% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  flex-direction: column !important;
  scroll-behavior: smooth !important;
  scrollbar-width: thin !important;
}
body.dark .image-modal-question {
  scrollbar-color: #ffffff #1e1e1e;
}
.image-modal-question img {
  height: auto !important;
  min-width: 400px !important;
  margin: 1rem auto !important;
}
/* Media Queries */
@media (max-width: 767px) {
  .lg-hidden {
    display: block;
  }
  .sm-hidden {
    display: none;
  }
  .swiper-button-prev,
  .swiper-button-next {
    width: 50px !important;
    height: 105px !important;
  }
  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 1.5rem !important;
  }
}
</style>
