<template>
  <div class="advert">
    <div
      data-aos="fade-left"
      data-aos-duration="1000"
      class="advert-left aos-init aos-animate"
    >
      <h1>منهج تعليمي متميز و اختبارات</h1>
      <p class="p-border">
        نهدف إلى تقديم تعليم ذو جودة عالية وشامل يغطي جميع جوانب التعلم. محتوى
        المنصة مبني على أسس علمية ومعايير دولية لضمان توفير أفضل تجربة تعليمية
        للطلاب.
      </p>
      <img alt="" srcset="@/assets/mohamedAbdelmaged/advert.png" />
    </div>
    <div
      data-aos="fade-right"
      data-aos-duration="1000"
      class="advert-right aos-init aos-animate"
    >
      <div class="adv">
        <img
          class="w-25"
          src=""
          alt=""
          srcset="@/assets/mohamedAbdelmaged/prop1.gif"
        />
        <div class="text d-flex flex-column">
          <h3>شرح منهج الفيزياء كامل بالتفصيل</h3>
          <p>
            هنشرحلك المنهج كامل بالتفصيل و هتقدر تعيد المحاضرة عشان تفهم الدروس
            اكتر
          </p>
        </div>
      </div>
      <div class="adv twoo">
        <img
          class="w-25"
          src=""
          alt=""
          srcset="@/assets/mohamedAbdelmaged/prop2.gif"
        />
        <div class="text d-flex flex-column">
          <h3>امتحانات و تركات في المنهج</h3>
          <p>امتحانات تفاعلية مستمرة عشان تعرف مستواك الدراسي</p>
        </div>
      </div>
      <div class="adv threee">
        <img
          class="w-25"
          src=""
          alt=""
          srcset="@/assets/mohamedAbdelmaged/prop3.gif"
        />
        <div class="text d-flex flex-column">
          <h3>مش هنسيب حاجه في المنهج غير و هنوضحهالك و نفهمالك</h3>
          <p>هنحل واجبات بعد كل محاضرة عشان نفهم اكتر و نتدرب على الامتحانات</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdvertComponent",
  data() {
    return {};
  },
};
</script>

<style scoped>
.advert {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 20px;
  text-align: center;
}
.advert-right,
.advert-left {
  padding: 1rem;
}
.advert-left {
  width: 50%;
}
.advert-right {
  margin: 1.5rem 0 3rem 0;
}
.advert-left h1 {
  font-size: 2.5rem;
  font-weight: 900;
  line-height: 1.8;
  margin-bottom: 20px;
  color: #000;
}
.advert-left p {
  color: #000000;
  font-size: 1rem;
  border-bottom: 5px solid #7a5af9;
  line-height: 1.8;
  padding-bottom: 10px;
}
.advert-left img {
  width: 100%;
  max-width: 300px;
  margin-top: 20px;
}
.advert-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.advert-right.adv {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.advert-right.adv img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 20px;
}
.advert-right.adv .text h3 {
  font-size: 1.5rem;
  font-weight: 700;
  color: #000;
}
.advert-right.adv .text p {
  font-size: 1rem;
  color: #000;
}
.advert-right.twoo img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 20px;
}
.advert-right.twoo .text h3 {
  font-size: 1.5rem;
  font-weight: 700;
  color: #000;
}
.advert-right.twoo .text p {
  font-size: 1rem;
  color: #000;
}
.advert-right.threee img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 20px;
}
.advert-right.threee .text h3 {
  font-size: 1.5rem;
  font-weight: 700;
  color: #000;
}
.advert-right.threee .text p {
  font-size: 1rem;
  color: #000000;
}
body.dark .advert-left h1,
body.dark .advert-left p,
body.dark .adv .text h3 {
  color: #ffffff !important;
}
body.dark .adv .text p {
  color: #ffffff90 !important;
}
@media (max-width: 992px) {
  .advert-right {
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column !important;
  }
  .advert {
    flex-direction: column;
  }
  .advert-right .adv,
  .advert-left,
  .advert-right {
    width: 100%;
  }
  .advert-right .adv img {
    margin-right: 0;
    margin-bottom: 20px;
  }
}
</style>
