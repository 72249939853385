import { createStore } from "vuex";

export default createStore({
  state: {
    isAuthenticated: false,
    userToken: null,
    darkMode: false,
    paymentID: null,
    student_name: null,
    is_loading_start_exam: false,
  },
  getters: {
    isDarkModeEnabled: (state) => state.darkMode,
    isAuthenticatedStatus: (state) => state.isAuthenticated,

  },
  mutations: {
    LOGIN_USER(state, token) {
      state.isAuthenticated = true;
      state.userToken = token;
    },
    LOGOUT_USER(state) {
      state.isAuthenticated = false;
      state.userToken = null;
    },
    SET_DARK_MODE(state, value) {
      state.darkMode = value;
    },
    SET_PAYMENT_ID(state, id) {
      state.paymentID = id;
    },
    SET_STUDENT_NAME(state, name) {
      state.student_name = name;
    },
    SET_LOADING_START_EXAM(state, value) {
      state.is_loading_start_exam = value;
    },
  },
  actions: {
    loginUser({ commit }, token) {
      commit("LOGIN_USER", token);
    },
    logoutUser({ commit }) {
      commit("LOGOUT_USER");
    },
    setDarkMode({ commit }, value) {
      commit("SET_DARK_MODE", value);
    },
    setPaymentID({ commit }, id) {
      commit("SET_PAYMENT_ID", id);
    },
    setStudentName({ commit }, name) {
      commit("SET_STUDENT_NAME", name);
    },
    setLoadingStartExam({ commit }, value) {
      commit("SET_LOADING_START_EXAM", value);
    },
  },
  modules: {},
});
