<template>
  <header class="header shadow-sm">
    <div class="d-flex justify-content-between align-items-center">
      <div class="options d-flex align-items-center">
        <AuthBtnsComponent />
        <DarkModeBtn />
      </div>

      <div class="logo" style="width: 180px">
        <router-link to="/" style="width: 100%">
          <LogoComponent />
        </router-link>
      </div>
    </div>
    <ProgressBarComponent />
  </header>
</template>
<script>
import DarkModeBtn from "./DarkModeBtn.vue";
import AuthBtnsComponent from "./AuthBtnsComponent.vue";
import ProgressBarComponent from "../ProgressBar/ProgressBarComponent.vue";
import LogoComponent from "../Logo/LogoComponent.vue";

export default {
  name: "HeaderComponent",
  components: {
    DarkModeBtn,
    LogoComponent,
    AuthBtnsComponent,
    ProgressBarComponent,
  },
  data() {
    return {
      darkMode: false,
      active: false,
      showLogout: false,
      showNotifications: false,
      notifications: [],
      is_loading: true,
    };
  },
  methods: {
    toggleMenu() {
      this.active = !this.active;
    },
    openLogoutBox(e) {
      // this.showLogout = true;
      if (!e.target.classList.contains("logout-box")) {
        this.showLogout ? (this.showLogout = false) : (this.showLogout = true);
      }
    },
    closeLogoutBox() {
      this.showLogout = false;
    },
  },
  mounted() {
    // close logout box when click outside
    document.addEventListener("click", (e) => {
      // Close logout box when click outside the box or the icon
      if (
        e.target.classList.contains("logout-box") ||
        e.target.classList.contains("logout-box-item")
      ) {
        return;
      }
      if (
        e.target.classList.contains("user-image") ||
        e.target.classList.contains("user-image-icon")
      ) {
        // if logout box is open and click on the icon again close the box
        if (this.showLogout) {
          this.showLogout = true;
          return;
        }
        return;
      }
      this.showLogout = false;
    });
    // close notifications box when click outside
    document.addEventListener("click", (e) => {
      // Close notifications box when click outside the box or the icon
      if (
        e.target.classList.contains("notifications-box") ||
        e.target.classList.contains("notifications-box-item")
      ) {
        return;
      }
      if (
        e.target.classList.contains("bell") ||
        e.target.classList.contains("bell-icon")
      ) {
        // if logout box is open and click on the icon again close the box
        if (this.showNotifications) {
          this.showNotifications = true;
          return;
        }
        return;
      }
      this.showNotifications = false;
    });
  },
};
</script>
<style scoped>
.header {
  padding: 1rem 2rem;
  position: sticky;
  top: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  z-index: 500;
}
body.dark .header {
  background-color: #1e1e1e !important;
}
@media (max-width: 767px) {
  .header {
    padding: 1rem 0.7rem;
  }
  .logo {
    width: 100px !important;
  }
}
@media (max-width: 575px) {
  .logo {
    width: 80px !important;
  }
}
</style>
