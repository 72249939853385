<template>
  <section class="py-5 courses-container-content">
    <div class="container">
      <h4 class="text-center fs-3 mb-5">كورسات المهندس / محمد عبد المجيد</h4>
      <h4 class="text-center fs-3 mb-5">#تريند_الفيزياء</h4>
      <!-- <ul class="course-filter">
        <li class="" data-id="1">الصف الاول الثانوي</li>
        <li class="" data-id="2">الصف الثاني الثانوي</li>
        <li class="active" data-id="3">الصف الثالث الثانوي</li>
      </ul> -->
      <div
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-duration="1200"
        data-aos-delay="50"
        class="aos-init aos-animate course-contaner"
      >
        <router-link
          class="course"
          v-for="course in courses"
          :key="course.id"
          :to="{ name: 'courseList', params: { phase: course.id } }"
        >
          <p class="num">{{ course.number }}</p>
          <h6 class="title">{{ course.title }}</h6>
        </router-link>
        <!-- <div class="course">
          <div class="image">
            <img
              src="https://abdelmiged.s3.amazonaws.com/covers/Untitled-1.png"
              alt=""
              class="w-100 h-100"
            />
          </div>
          <div class="body">
            <div class="name-price">
              <h6>كورس الشهر الأول 3ث</h6>
              <p class="price m-0">ج 150</p>
            </div>
            <p>
              الكورس مكون من اربع محاضرات 1- التيار الكهربي وفرق الجهد وقانون
              اوم. 2- المقاومه الكهربيه والمقارنه بين مقاومتين (نسب المقاومات)
              والقدره والطاقه الكهربيه. 3- بدايه التوالي والتوازي. 4- حل افكار ا
            </p>
          </div>
          <div class="content">
            <p class="units"><FaShapes />1الوحدات</p>
            <p class="lessons"><FaBookOpenReader />4الدروس</p>
            <p class="exams"><BsFileEarmarkFill />0الفايلات</p>
          </div>
          <div class="course-footer">
            <a href="/course-preview/12"
              >الذهاب للكورس
              <div><AkCircleChevronLeftFill /></div
            ></a>
          </div>
        </div>
        <div class="course">
          <div class="image">
            <img
              src="https://abdelmiged.s3.amazonaws.com/covers/SAVE_%D9%A2%D9%A0%D9%A2%D9%A4%D9%A0%D9%A9%D9%A1%D9%A5_%D9%A2%D9%A4%D9%A3%D9%A1%D9%A1%D9%A8.jpg"
              alt=""
              class="w-100 h-100"
            />
          </div>
          <div class="body">
            <div class="name-price">
              <h6>كورس الشهر التاني 3ث</h6>
              <p class="price m-0">ج 150</p>
            </div>
            <p>
              الكورس مكون من أربع محاضرات 1-بدايه قانون اوم للدائره المغلقه.
              2-تطبيق علي قانون أوم للدائره المغلقه وكيفيه حل مسائل المصابيح. 3-
              قانون كيرشوف الأول. 4-قانون كيرشوف التاني.
            </p>
          </div>
          <div class="content">
            <p class="units"><FaShapes />1الوحدات</p>
            <p class="lessons"><FaBookOpenReader />4الدروس</p>
            <p class="exams"><BsFileEarmarkFill />0الفايلات</p>
          </div>
          <div class="course-footer">
            <a href="/course-preview/14"
              >الذهاب للكورس
              <div><AkCircleChevronLeftFill /></div
            ></a>
          </div>
        </div>
        <div class="course">
          <div class="image">
            <img
              src="https://abdelmiged.s3.amazonaws.com/covers/SAVE_%D9%A2%D9%A0%D9%A2%D9%A4%D9%A1%D9%A0%D9%A2%D9%A5_%D9%A2%D9%A2%D9%A0%D9%A1%D9%A4%D9%A5.jpg"
              alt=""
              class="w-100 h-100"
            />
          </div>
          <div class="body">
            <div class="name-price">
              <h6>كورس الشهر التالت 3ث</h6>
              <p class="price m-0">ج 150</p>
            </div>
            <p>
              بدايه الفصل الثاني. 1-شرح الفيض المغناطيسي والسلك المستقيم. 2-شرح
              محصله فيض سلكين ونقطه التعادل. 3-شرح الملف الدائري وحل عليه. 4-شرح
              الملف الحلزوني (اللولبي).
            </p>
          </div>
          <div class="content">
            <p class="units"><FaShapes />1الوحدات</p>
            <p class="lessons"><FaBookOpenReader />4الدروس</p>
            <p class="exams"><BsFileEarmarkFill />0الفايلات</p>
          </div>
          <div class="course-footer">
            <a href="/course-preview/16"
              >الذهاب للكورس
              <div><AkCircleChevronLeftFill /></div
            ></a>
          </div>
        </div> -->
      </div>
    </div>
  </section>
</template>

<script>
import config from "@/services/config";
import //   FaShapes,
//   FaBookOpenReader,
//   BsFileEarmarkFill,
//   AkCircleChevronLeftFill,
"@kalimahapps/vue-icons";
import axios from "axios";
export default {
  name: "CoursesHomePageComponent",
  components: {
    // FaShapes,
    // FaBookOpenReader,
    // BsFileEarmarkFill,
    // AkCircleChevronLeftFill,
  },
  data() {
    return {
      courses: [
        {
          id: 1,
          title: "الصف الاول الثانوي",
          number: 1,
        },
        {
          id: 2,
          title: "الصف الثاني الثانوي",
          number: 2,
        },
        {
          id: 3,
          title: "الصف الثالث الثانوي",
          number: 3,
        },
      ],
    };
  },
  methods: {
    getCourses() {
      axios
        .get(`${config.baseURL}course/course-list`)
        .then((res) => {
          this.courses = res.data;
          console.log(res.data);
          console.log(this.courses);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    // this.getCourses();
  },
  mounted() {
    const courseFilter = document.querySelectorAll(".course-filter li");
    const courses = document.querySelectorAll(".course");
    courseFilter.forEach((filter) => {
      filter.addEventListener("click", () => {
        courseFilter.forEach((f) => f.classList.remove("active"));
        filter.classList.add("active");
        const id = filter.getAttribute("data-id");
        courses.forEach((course) => {
          course.style.display = "none";
          if (course.getAttribute("data-id") === id || id === "all") {
            course.style.display = "block";
          }
        });
      });
    });
  },
};
</script>

<style scoped>
.courses-container-content {
  padding: 3rem 0;
}
.courses-container-content h4 {
  color: #000000;
  font-weight: 900;
  font-size: 2rem;
}
.course-filter {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 2rem;
}
.course-filter li {
  border-radius: 24px;
  box-shadow: 0 4px 30px #0000001a;
  cursor: pointer;
  padding: 10px;
  text-align: center;
  transition: all 0.5s ease-in-out;
  list-style: none;
}
.course-filter li:hover,
.course-filter li.active {
  background-color: #7a5af9;
  color: #fff;
  background-color: #0278ff !important;
  color: #fff;
  transition: all 0.5s ease-in-out;
}
.course-contaner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 2rem;
}
.course {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 1px 2px 0 #3c40434d, 0 1px 3px 1px #3c404326;
  padding: 20px;
  width: calc(100% / 3 - 20px);
  transition: all 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
  color: #000000;
  /* max-height: 550px; */
  /* min-height: 550px; */
  /* position: relative; */
}
.course:hover {
  background-color: #7a5af9 !important;
  color: #fff !important;
  transition: all 0.5s ease-in-out;
}
.title {
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  color: #7a5af9;
  transition: all 0.5s ease-in-out;
}
body.dark .title {
  color: #ffffff;
}
.course:hover .title {
  color: #ffffff;
  transition: all 0.5s ease-in-out;
}
.num {
  font-size: 75px;
  font-weight: bold;
  color: transparent;
  -webkit-text-stroke: 2px #7a5af9;
  text-align: center;
  /* position: absolute; */
  /* top: 50%; */
  /* right: 1rem; */
  /* transform: translate(-1rem, -50%); */
  /* z-index: 0; */
  margin: 0;
  transition: all 0.5s ease-in-out;
}
.course:hover .num {
  -webkit-text-stroke: 2px #ffffff;
  transition: all 0.5s ease-in-out;
}
body.dark .num {
  -webkit-text-stroke: 2px #ffffff;
  transition: all 0.5s ease-in-out;
}
.course .image {
  height: 200px;
  overflow: hidden;
  border-radius: 20px;
}
.course .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.course .body {
  padding: 10px 0;
}
.course .name-price h6 {
  font-size: 1.2rem;
  font-weight: 700;
  color: #000;
  transition: all 0.5s ease-in-out;
}
.course .price {
  color: #7a5af9;
  text-align: left;
  transition: all 0.5s ease-in-out;
}
.course:hover .content p,
.course:hover .name-price h6,
.course:hover .price {
  color: #fff;
  transition: all 0.5s ease-in-out;
}
.course .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}
.course .content p {
  font-size: 0.9rem;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin: 0;
  transition: all 0.5s ease-in-out;
}
.course .course-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}
.course .course-footer a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-radius: 20px;
  background-color: #7a5af9;
  color: #fff;
  width: 100%;
  text-decoration: none;
  align-items: center;
  background-color: #00bd99;
  border-radius: 24px;
  color: #fff;
  display: flex;
  font-weight: 700;
  justify-content: space-between;
  padding: 5px;
  transition: all 0.5s ease-in-out;
}
.course .course-footer a div {
  background-color: #fff;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.course .course-footer a div svg {
  color: #00bd99;
  transition: all 0.5s ease-in-out;
}
.course .course-footer a:hover {
  background-color: #fff;
  color: #00bd99;
  transition: all 0.5s ease-in-out;
}
.course .course-footer a:hover div {
  background-color: #00bd99;
  transition: all 0.5s ease-in-out;
}
.course .course-footer a:hover div svg {
  color: #fff;
  transition: all 0.5s ease-in-out;
}
body.dark .courses-container-content h4 {
  color: #ffffff;
}
body.dark .course-filter li {
  background-color: #1e1e1e;
  color: #ffffff;
}
body.dark .course {
  background-color: #1e1e1e;
  color: #ffffff;
}
body.dark .course .name-price h6,
body.dark .course .price,
body.dark .course .content p {
  color: #ffffff;
}
body.dark .course:hover {
  background-color: #7a5af9 !important;
  color: #fff !important;
}
body.dark .course:hover .content p,
body.dark .course:hover .name-price h6,
body.dark .course:hover .price {
  color: #fff;
}
body.dark .course .course-footer a:hover div svg {
  color: #fff;
}
@media (max-width: 992px) {
  .course-contaner {
    /* flex-direction: column;
    align-items: center; */
  }
  .course {
    /* width: 100%; */
  }
  .course .image {
    height: 200px;
  }
  .course .image img {
    height: 200px !important;
    width: 100% !important;
    object-fit: fill;
  }
}
@media (max-width: 768px) {
  .course-contaner {
    flex-direction: column;
    align-items: center;
  }
  .course {
    width: 100%;
    margin-bottom: 20px;
  }
  .course-filter {
    flex-direction: column;
  }
}

@media (max-width: 576px) {
  .course-filter {
    flex-wrap: wrap;
  }
  .course-filter li {
    padding: 10px;
  }
}

@media (max-width: 400px) {
  .course .name-price h6 {
    font-size: 1rem;
  }
  .course .price {
    font-size: 0.9rem;
  }
  .course .content p {
    font-size: 0.8rem;
  }
  .course .course-footer a {
    padding: 10px;
  }
}
</style>
