<template>
  <div class="exam-student-answers-container">
    <div class="header">
      <h1 class="header-title">الإجابات</h1>
    </div>
    <!-- Loading -->
    <LoadingComponent v-if="is_loading" class="mt-5" />
    <!-- Error -->
    <ErrorComponent
      v-else-if="error && !is_loading"
      :error="error"
      class="mt-5"
    />
    <!-- No data -->
    <div class="no-data-container" v-else-if="student_answers?.length === 0">
      <h2>لا يوجد بيانات</h2>
    </div>
    <div class="body" v-else>
      <div class="head">
        <div class="top">
          <div class="num-questions">
            <span>
              <BsQuestionSquareFill />
              عدد الأسئلة
            </span>
            <span class="num">{{ answers?.exam_score }}</span>
          </div>
          <div class="num-answered-questions">
            <span>
              <AiAcademiaSquare />
              عدد الأسئلة المحلولة
            </span>
            <span class="num">{{ answers?.student_answers?.length }}</span>
          </div>
          <div class="result p-2">
            <span>
              <CaResultDraft
                :class="{
                  success: answers?.is_succeeded,
                  failed: !answers?.is_succeeded,
                }"
              />
              النتيجة
            </span>
            <!-- <span
              class="num"
              :class="{
                success: answers?.is_succeeded,
                failed: !answers?.is_succeeded,
              }"
            >
              %
              {{
                Math.round((answers?.student_score / answers?.exam_score) * 100)
              }}
            </span> -->
            <div class="result-percent">
              <div class="outer">
                <div class="inner">
                  <div class="percentage">{{ percentage }}%</div>
                </div>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                width="100%"
                height="100%"
                class="circle"
              >
                <defs>
                  <linearGradient id="GradientColor">
                    <stop offset="0%" stop-color="#e91e63" />
                    <stop offset="100%" stop-color="#673ab7" />
                  </linearGradient>
                </defs>
                <circle
                  cx="-32"
                  cy="32"
                  r="27"
                  stroke-linecap="round"
                  :style="{
                    'stroke-dasharray': circlePerimeter,
                    '--percentage': dashOffset,
                    '--circlePerimeter': circlePerimeter,
                    '--time': `${computedPercentage * 40}ms`,
                    stroke: `${
                      computedPercentage >= 50
                        ? computedPercentage < 65
                          ? '#ff9800'
                          : computedPercentage < 75
                          ? '#ffc107'
                          : computedPercentage < 85
                          ? '#a7d160'
                          : '#32d438'
                        : '#e9334e'
                    }`,
                  }"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="bottom">
          <div class="num-correct-answers">
            <span>
              <UiCheckSquare />
              عدد الإجابات الصحيحة
            </span>
            <span class="num">{{ answers?.student_score }}</span>
          </div>
          <div class="num-wrong-answers">
            <span>
              <PhFillXSquare />
              عدد الإجابات الخاطئة
            </span>
            <span class="num">{{ num_wrongs }}</span>
          </div>
          <div class="result p-2">
            <span>
              <CaResultDraft
                :class="{
                  success: answers?.is_succeeded,
                  failed: !answers?.is_succeeded,
                }"
              />
              النتيجة
            </span>
            <!-- <span
              class="num"
              :class="{
                success: answers?.is_succeeded,
                failed: !answers?.is_succeeded,
              }"
            >
              %
              {{
                Math.round((answers?.student_score / answers?.exam_score) * 100)
              }}
            </span> -->
            <div class="result-percent">
              <div class="outer">
                <div class="inner">
                  <div class="percentage">{{ percentage }}%</div>
                </div>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                width="100%"
                height="100%"
                class="circle"
              >
                <defs>
                  <linearGradient id="GradientColor">
                    <stop offset="0%" stop-color="#e91e63" />
                    <stop offset="100%" stop-color="#673ab7" />
                  </linearGradient>
                </defs>
                <circle
                  cx="-32"
                  cy="32"
                  r="27"
                  stroke-linecap="round"
                  :style="{
                    'stroke-dasharray': circlePerimeter,
                    '--percentage': dashOffset,
                    '--circlePerimeter': circlePerimeter,
                    '--time': `${computedPercentage * 40}ms`,
                    stroke: `${
                      computedPercentage >= 50
                        ? computedPercentage < 65
                          ? '#ff9800'
                          : computedPercentage < 75
                          ? '#ffc107'
                          : computedPercentage < 85
                          ? '#a7d160'
                          : '#32d438'
                        : '#e9334e'
                    }`,
                  }"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="bottom">
          <div class="time">
            <span>
              <PhHourglassMedium />
              الوقت المستغرق
            </span>
            <span class="num">{{
              getTimeTaken(
                answers?.student_submitted_exam_at,
                answers?.student_started_exam_at
              )
            }}</span>
          </div>
        </div>
      </div>
      <div class="separator"></div>
      <div class="header header-evaluation">
        <h1 class="header-title">تقيمك</h1>
      </div>
      <AnswersStatisticsComponent
        :analysis_data="analysis_data"
        :is_loading="is_loading"
      />
      <div class="separator"></div>
      <div class="body-body">
        <ExamStudentAnswerItemComponent
          v-for="(answer, index) in student_answers"
          :key="answer.id"
          :data="answer"
          :number_question="index + 1"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LoadingComponent from "@/components/Loading/LoadingComponent.vue";
import ErrorComponent from "@/components/Error/ErrorComponent.vue";
import ExamStudentAnswerItemComponent from "@/components/Profile/ExamStudentAnswerItem/ExamStudentAnswerItemComponent.vue";
import AnswersStatisticsComponent from "@/components/Profile/AnswersStatistics/AnswersStatisticsComponent.vue";
import config from "@/services/config";
import axios from "axios";
import {
  AiAcademiaSquare,
  BsQuestionSquareFill,
  CaResultDraft,
  PhFillXSquare,
  UiCheckSquare,
  PhHourglassMedium,
} from "@kalimahapps/vue-icons";

export default {
  name: "ExamStudentAnswersView",
  components: {
    LoadingComponent,
    ErrorComponent,
    ExamStudentAnswerItemComponent,
    AnswersStatisticsComponent,
    AiAcademiaSquare,
    BsQuestionSquareFill,
    CaResultDraft,
    PhFillXSquare,
    UiCheckSquare,
    PhHourglassMedium,
  },
  data() {
    return {
      student_answers: [],
      answers: {},
      num_wrongs: 0,
      is_loading: true,
      error: null,
      analysis_data: [],
      percentage: 0,
    };
  },
  methods: {
    getAnswers() {
      axios
        .get(`${config.baseURL}exams/${this.$route?.params?.id}/result`, {
          headers: {
            auth: this.$store.state.userToken,
          },
        })
        .then((res) => {
          const data = res.data;
          this.answers = data;
          this.analysis_data = data;

          // تحويل إجابات الطالب وإضافة الإجابة الصحيحة لكل سؤال
          this.student_answers = (data?.student_answers || []).map((answer) => {
            const correctAnswerObj = (data?.correct_answers || []).find(
              (correctAnswer) =>
                correctAnswer.question_id === answer.question_id
            );
            return {
              ...answer,
              correct_answers: correctAnswerObj?.correct_answers?.[0] || null,
            };
          });

          // this.answers = res?.data;
          // this.student_answers = res?.data?.student_answers?.map((answer) => {
          //   return {
          //     ...answer,
          //     correct_answers: res?.data?.correct_answers?.find(
          //       (correctAnswer) =>
          //         correctAnswer.question_id === answer.question_id
          //     )?.correct_answers[0],
          //   };
          // });

          this.num_wrongs = this.student_answers.reduce(
            (acc, answer) => acc + (answer.is_correct ? 0 : 1),
            0
          );
          // filter questions by question_category_id
          const groupedQuestions = {};
          this.analysis_data = this.analysis_data?.student_answers.forEach(
            (answer) => {
              const categoryId = answer.question_category_id;
              const category = answer.question_category;
              if (!groupedQuestions[categoryId]) {
                groupedQuestions[categoryId] = {
                  question_category_id: categoryId,
                  question_category: category,
                  total: 0,
                  correct: 0,
                };
              }
              groupedQuestions[categoryId].total++;
              if (answer.is_correct) {
                groupedQuestions[categoryId].correct++;
              }
            }
          );
          this.analysis_data = Object.values(groupedQuestions);

          console.log(this.analysis_data);
        })
        .catch((err) => {
          console.log(err.message);
          this.error = err?.response?.status
            ? err.response.status
            : err.message;
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
    getTimeTaken(student_submitted_exam_at, student_started_exam_at) {
      if (!student_submitted_exam_at || !student_started_exam_at) {
        return "00:00:00";
      }
      const start = new Date(student_started_exam_at);
      const end = new Date(student_submitted_exam_at);
      const diff = end - start; // الفرق بالمللي ثانية
      const totalSeconds = Math.floor(diff / 1000);
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;
      // لتنسيق الوقت بصيغة HH:MM:SS مع إضافة أصفار بادئة لو لازم
      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    },
  },
  computed: {
    computedPercentage() {
      if (!this.answers.exam_score || !this.answers.student_score) {
        return 0;
      }
      const value = Math.round(
        (this.answers.student_score / this.answers.exam_score) * 100
      );
      return Math.min(Math.max(value, 0), 100);
    },
    dashOffset() {
      return (
        this.circlePerimeter -
        (this.circlePerimeter * this.computedPercentage) / 100
      );
    },
    circlePerimeter() {
      return 2 * Math.PI * 27;
    },
  },
  created() {
    this.getAnswers();
  },
  mounted() {
    let counter = 0;
    setInterval(() => {
      if (counter < this.computedPercentage) {
        counter++;
        this.percentage = counter;
      } else {
        clearInterval();
      }
    }, 40);
  },
};
</script>

<style scoped>
.result-percent {
  width: 4rem;
  height: 4rem;
  position: relative;
}
.result-percent .outer {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  padding: 0.25rem;
  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
body.dark .result-percent .outer {
  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(0, 0, 0, 0.3);
}
.result-percent .inner {
  width: 75%;
  height: 75%;
  border-radius: 50%;
  box-shadow: inset 4px 4px 6px -1px rgba(0, 0, 0, 0.2),
    inset -4px -4px 6px -1px rgba(255, 255, 255, 0.7),
    -0.5px -0.5px 0px rgba(255, 255, 255, 1),
    0.5px 0.5px 0px rgba(0, 0, 0, 0.15), 0px 12px 10px -10px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
}
body.dark .result-percent .inner {
  box-shadow: inset 4px 4px 6px -1px rgba(0, 0, 0, 0.2),
    inset -4px -4px 6px -1px rgba(0, 0, 0, 0.3),
    -0.5px -0.5px 0px rgba(0, 0, 0, 0.5), 0.5px 0.5px 0px rgba(0, 0, 0, 0.15),
    0px 12px 10px -10px rgba(0, 0, 0, 0.05);
}
.result-percent .percentage {
  font-size: 1rem;
  font-weight: 600;
  color: #000000;
}
body.dark .result-percent .percentage {
  color: #ffffff;
}
.result-percent circle {
  fill: none;
  stroke-width: 0.5rem;
  stroke-dashoffset: 0;
  transform: rotate(-90deg);
  animation: anim var(--time) linear forwards;
}
.result-percent .circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@keyframes anim {
  0% {
    stroke-dashoffset: var(--circlePerimeter);
  }
  100% {
    stroke-dashoffset: var(--percentage);
  }
}

.no-data-container,
.loading-container {
  margin-top: 5rem;
}
.no-data-container h2 {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  color: #000000;
}
body.dark .no-data-container h2 {
  color: #ffffff;
}
.exam-student-answers-container {
  min-height: 100vh;
  background-color: #f9f9f9;
}
.exam-student-answers-container {
  min-height: 100vh;
  background-color: #f9f9f9;
}
body.dark .exam-student-answers-container {
  background-color: #13323a;
}
.exam-student-answers-container .header {
  width: 100%;
  height: 115px;
  /* background-image: url("../../assets/DALL·E 2024-12-11 01.58.57 - A seamless Arabic geometric patterns background with soft and comfortable colors for the eyes. The design features intricate Islamic motifs with inter.webp"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
body.dark .exam-student-answers-container .header .header-title {
  background-color: #13323a;
  border: 1px solid #ffffff33;
  color: #ffffff;
}
.exam-student-answers-container .header .header-title {
  background-color: #f9f9f9;
  border: 1px solid #00000033;
  color: #000000;
  padding: 0.7rem 6.8rem;
  border-radius: 2.5rem;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
  position: absolute;
  bottom: -50%;
  right: 50%;
  transform: translate(50%, -50%);
}
.exam-student-answers-container .header.header-evaluation {
  height: 2rem;
}
.exam-student-answers-container .header.header-evaluation .header-title {
  bottom: 100%;
  right: 50%;
  transform: translate(50%, -50%);
}
.exam-student-answers-container .body {
  padding: 5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.exam-student-answers-container .body .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  width: 615px;
}
body.dark .exam-student-answers-container .body .head .bottom,
body.dark .exam-student-answers-container .body .head .top {
  background-color: #1d4955;
}
.exam-student-answers-container .body .head .bottom,
.exam-student-answers-container .body .head .top {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0.5rem;
  background-color: #f0f0f0;
  border-radius: 1rem;
}
.exam-student-answers-container .body .head .bottom {
  justify-content: space-between;
}
body.dark
  .exam-student-answers-container
  .body
  .head
  .bottom
  .num-correct-answers,
body.dark .exam-student-answers-container .body .head .bottom .time,
body.dark
  .exam-student-answers-container
  .body
  .head
  .bottom
  .num-wrong-answers {
  color: #ffffff;
}
.exam-student-answers-container .body .head .bottom .time {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000000;
}
.exam-student-answers-container .body .head .bottom .num-correct-answers,
.exam-student-answers-container .body .head .bottom .num-wrong-answers {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000000;
}
body.dark
  .exam-student-answers-container
  .body
  .head
  .bottom
  .num-wrong-answers {
  border-right: 1px solid #ffffff33;
}
.exam-student-answers-container .body .head .bottom .time,
.exam-student-answers-container .body .head .bottom .num-correct-answers {
  padding: 0 1rem;
}
.exam-student-answers-container .body .head .bottom .num-wrong-answers {
  padding: 0 1rem;
  border-right: 1px solid #00000033;
}
body.dark .exam-student-answers-container .body .head .top .num-questions,
body.dark
  .exam-student-answers-container
  .body
  .head
  .top
  .num-answered-questions,
body.dark .exam-student-answers-container .body .head .top .result {
  color: #ffffff;
}
.exam-student-answers-container .body .head .top .num-questions,
.exam-student-answers-container .body .head .top .num-answered-questions,
.exam-student-answers-container .body .head .top .result {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  color: #000000;
}
.exam-student-answers-container .body .head .top .num-answered-questions {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-left: 1px solid #00000033;
  border-right: 1px solid #00000033;
}
body.dark
  .exam-student-answers-container
  .body
  .head
  .top
  .num-answered-questions {
  border-left: 1px solid #ffffff33;
  border-right: 1px solid #ffffff33;
}
.exam-student-answers-container .body .head .bottom .time span,
.exam-student-answers-container .body .head .bottom .num-correct-answers span,
.exam-student-answers-container .body .head .bottom .num-wrong-answers span,
.exam-student-answers-container .body .head .top .num-questions span,
.exam-student-answers-container .body .head .top .num-answered-questions span,
.exam-student-answers-container .body .head .top .result span {
  font-size: 1rem;
  font-weight: 600;
}
body.dark
  .exam-student-answers-container
  .body
  .head
  .bottom
  .num-correct-answers
  .num,
body.dark .exam-student-answers-container .body .head .bottom .time .num,
body.dark
  .exam-student-answers-container
  .body
  .head
  .bottom
  .num-wrong-answers
  .num,
body.dark .exam-student-answers-container .body .head .top .num-questions .num,
body.dark
  .exam-student-answers-container
  .body
  .head
  .top
  .num-answered-questions
  .num,
body.dark .exam-student-answers-container .body .head .top .result .num {
  background-color: #13323a;
  color: #ffffff;
}
.exam-student-answers-container .body .head .bottom .result .num,
.exam-student-answers-container .body .head .bottom .time .num,
.exam-student-answers-container .body .head .bottom .num-correct-answers .num,
.exam-student-answers-container .body .head .bottom .num-wrong-answers .num,
.exam-student-answers-container .body .head .top .num-questions .num,
.exam-student-answers-container .body .head .top .num-answered-questions .num,
.exam-student-answers-container .body .head .top .result .num {
  font-size: 1.25rem;
  font-weight: 500;
  background-color: #ffffff;
  color: #000000;
  padding: 0.5rem 0.5rem;
  border-radius: 0.625rem;
}
body.dark .exam-student-answers-container .body .head .top .result {
  background-color: #13323a;
}
.exam-student-answers-container .body .head .top .result {
  background-color: #fff;
  padding-right: 0.5rem;
  border-radius: 0.625rem;
}
.exam-student-answers-container .body .head .bottom .num-wrong-answers {
  padding-right: 2rem;
}
.num-answered-questions span,
.num-questions span,
.num-correct-answers span,
.time span,
.num-wrong-answers span,
.result span {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}
.num-answered-questions svg,
.num-questions svg,
.num-correct-answers svg,
.time svg,
.num-wrong-answers svg,
.result svg {
  width: 1.5rem;
  height: 1.5rem;
}
.num-answered-questions svg,
.num-questions svg {
  color: #000000;
}
body.dark .num-answered-questions svg,
body.dark .num-questions svg {
  color: #ffffff;
}
.time svg,
.num-correct-answers svg {
  color: #2abf7a;
}
body.dark .time svg,
body.dark .num-correct-answers svg {
  color: #038c4c;
}
.num-wrong-answers svg {
  color: #e9334e;
}
body.dark .num-wrong-answers svg {
  color: #e71c39;
}
.result svg.failed {
  color: #e9334e;
}
body.dark .result svg.failed {
  color: #e71c39;
}
.result svg.success {
  color: #2abf7a;
}
body.dark .result svg.success {
  color: #038c4c;
}
body.dark
  .exam-student-answers-container
  .body
  .head
  .bottom
  .num-wrong-answers
  .num,
body.dark .exam-student-answers-container .body .head .top .result .num {
  background-color: #e9334e;
}
body.dark
  .exam-student-answers-container
  .body
  .head
  .bottom
  .num-wrong-answers
  .num.success,
body.dark
  .exam-student-answers-container
  .body
  .head
  .top
  .result
  .num.success {
  background-color: #2abf7a;
}
body.dark .exam-student-answers-container .body .head .top .result .num.failed {
  background-color: #038c4c;
}
.exam-student-answers-container .body .head .top .result .num.failed,
.exam-student-answers-container .body .head .bottom .result .num.failed,
.exam-student-answers-container .body .head .bottom .num-wrong-answers .num,
body.dark .exam-student-answers-container .body .bottom .result .num-success,
body.dark
  .exam-student-answers-container
  .body
  .head
  .bottom
  .num-wrong-answers
  .num,
body.dark .exam-student-answers-container .body .head .top .result .num.failed {
  background-color: #e71c39;
  color: #ffffff;
}
.exam-student-answers-container .body .head .bottom .result .num.success,
.exam-student-answers-container .body .head .top .result .num.success {
  background-color: #2abf7a;
  color: #ffffff;
}
body.dark
  .exam-student-answers-container
  .body
  .head
  .bottom
  .result
  .num.success,
body.dark .exam-student-answers-container .body .head .top .result .num.success,
body.dark .exam-student-answers-container .body .head .bottom .time .num,
body.dark
  .exam-student-answers-container
  .body
  .head
  .bottom
  .num-correct-answers
  .num {
  background-color: #038c4c;
}
.exam-student-answers-container .body .head .bottom .time .num,
.exam-student-answers-container .body .head .bottom .num-correct-answers .num {
  background-color: #2abf7a;
  color: #ffffff;
}
.exam-student-answers-container .body .separator {
  width: 100%;
  height: 1px;
  background-color: #00000033;
  margin: 4rem 0;
}
body.dark .exam-student-answers-container .body .separator {
  background-color: #ffffff33;
}
.exam-student-answers-container .body .body-body {
  width: 100%;
  padding: 0 8rem;
}
.exam-student-answers-container .body .head .bottom .result {
  display: none;
  width: 100%;
  padding: 0 2rem 0 1.25rem !important;
  color: #000000;
  font-size: 1rem;
  font-weight: 600;
}
body.dark .exam-student-answers-container .body .head .bottom .result {
  color: #ffffff;
}
body.dark
  .exam-student-answers-container
  .body
  .head
  .bottom
  .result
  .num.success {
  color: #ffffff;
  background-color: #038c4c;
}
.exam-student-answers-container .body .head .bottom .result .num.success {
  color: #ffffff;
  background-color: #e9334e;
  font-size: 1.25rem;
  font-weight: 500;
  padding: 0.5rem 0.5rem;
  border-radius: 0.625rem;
}
@media (max-width: 992px) {
  .exam-student-answers-container .body .body-body {
    padding: 0 4rem;
  }
}
@media (max-width: 768px) {
  .exam-student-answers-container .header {
    background-image: none;
  }
  .exam-student-answers-container .body {
    padding: 2rem 0;
  }
  .exam-student-answers-container .body .head {
    gap: 0rem;
    width: 100%;
  }
  .exam-student-answers-container .body .head .bottom,
  .exam-student-answers-container .body .head .top {
    flex-direction: column;
    gap: 1rem;
    border-radius: 0;
  }
  .exam-student-answers-container .body .head .bottom .result {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .exam-student-answers-container .body .head .top .num-questions,
  .exam-student-answers-container .body .head .top .num-answered-questions,
  .exam-student-answers-container .body .head .top .result {
    justify-content: space-between;
    padding: 0 2rem;
    width: 100%;
  }
  .exam-student-answers-container .body .head .top .result {
    display: none;
  }
  .exam-student-answers-container .body .head .bottom .num-wrong-answers,
  .exam-student-answers-container .body .head .bottom .num-correct-answers,
  .exam-student-answers-container .body .head .bottom .time,
  body.dark .exam-student-answers-container .body .head .bottom .time,
  body.dark
    .exam-student-answers-container
    .body
    .head
    .bottom
    .num-correct-answers {
    width: 100%;
    padding: 0 2rem;
    border: none;
  }
  .exam-student-answers-container .body .head .top .num-answered-questions,
  body.dark
    .exam-student-answers-container
    .body
    .head
    .bottom
    .num-wrong-answers,
  body.dark
    .exam-student-answers-container
    .body
    .head
    .top
    .num-answered-questions {
    border: none;
  }
  .exam-student-answers-container .header .header-title {
    padding: 0.7rem 3.5rem;
    font-size: 1.25rem;
    position: relative;
    width: fit-content;
    border: none;
  }
  .exam-student-answers-container .header.header-evaluation {
    height: 8rem;
  }
  .exam-student-answers-container .header.header-evaluation .header-title {
    bottom: -50%;
    right: 50%;
    transform: translate(50%, -50%);
  }
  body.dark .exam-student-answers-container .header .header-title {
    border: none;
  }
  body.dark .exam-student-answers-container .header::before {
    background-color: #ffffff33;
  }
  .exam-student-answers-container .header::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #00000033;
    bottom: 50%;
    left: 0;
  }
  .exam-student-answers-container .body .body-body {
    margin: 4rem 0;
    padding: 0 2rem;
  }
  .exam-student-answers-container .body .separator {
    display: none;
  }
  .exam-student-answers-container .body .head .bottom .time {
    padding: 0 2rem 0 0.5rem !important;
  }
}
@media (max-width: 576px) {
  .exam-student-answers-container .body .body-body {
    padding: 0 1rem;
  }
  .exam-student-answers-container .body .head .bottom .result {
    padding: 0 1rem 0 0.5rem !important;
  }
  .exam-student-answers-container .body .head .top .num-questions,
  .exam-student-answers-container .body .head .top .num-answered-questions,
  .exam-student-answers-container .body .head .bottom .num-wrong-answers,
  .exam-student-answers-container .body .head .bottom .num-correct-answers {
    padding: 0 1rem !important;
  }
  .exam-student-answers-container .body .head .bottom .time {
    padding: 0 1rem 0 0 !important;
  }
}
</style>
