<template>
  <div class="container">
    <div class="courses-statistics-box-container">
      <!-- Loading -->
      <LoadingComponent v-if="is_loading" />
      <!-- Error -->
      <ErrorComponent v-else-if="error && !is_loading" :error="error" />
      <!-- Data -->
      <template v-if="!is_loading && analysis_data">
        <!-- lessons -->
        <div class="courses-statistics-box-item">
          <h3 class="title">عدد الفيديوهات اللي شوفتها</h3>
          <div class="statistics-prog-container">
            <span
              class="statistics-prog"
              :style="{
                width: analysis_data?.progress_in_all_courses?.progress + '%',
              }"
            ></span>
          </div>
          <div class="bottom justify-content-between">
            <span class="video">
              <FaVideo />
              {{ analysis_data?.progress_in_all_courses?.watched_lessons }}
              فيديو من
              {{ analysis_data?.progress_in_all_courses?.total_lessons }}
            </span>
            <span class="average">
              {{ analysis_data?.progress_in_all_courses?.progress }} %
            </span>
          </div>
        </div>
        <!-- exams -->
        <div class="courses-statistics-box-item">
          <h3 class="title">عدد الاختبارات اللي خلصتها</h3>
          <div class="statistics-prog-container">
            <span
              class="statistics-prog"
              :style="{ width: analysis_data?.exam_progress?.progress + '%' }"
            ></span>
          </div>
          <div class="bottom justify-content-between">
            <span class="test">
              <PhFillTestTube />
              {{ analysis_data?.exam_progress?.total_exams_taken }} اختبار من
              {{ analysis_data?.exam_progress?.total_exams_should_be_taken }}
            </span>
            <span class="average">
              {{ analysis_data?.exam_progress?.progress }} %
            </span>
          </div>
        </div>

        <!-- average results -->
        <div class="courses-statistics-box-item">
          <h3 class="title">متوسط النتائج اللي جبتها</h3>
          <div class="statistics-prog-container">
            <span
              class="statistics-prog"
              :style="{ width: analysis_data?.exam_progress?.average + '%' }"
            ></span>
          </div>
          <div class="bottom justify-content-between">
            <span class="video">
              <CaResult />
              {{ analysis_data?.exam_progress?.total_student_scores }}
              درجة من
              {{ analysis_data?.exam_progress?.total_exam_scores }}
            </span>
            <span class="average">
              {{ analysis_data?.exam_progress?.average }}%
            </span>
          </div>
        </div>
        <!-- Chart -->
        <div class="chart-container-percent">
          <div class="content-chart">
            <div class="result-percent">
              <div class="outer">
                <div class="inner">
                  <div class="percentage">
                    {{
                      analysis_data?.progress_in_all_courses?.watched_lessons ||
                      0
                    }}/
                    {{
                      analysis_data?.progress_in_all_courses?.total_lessons || 0
                    }}
                  </div>
                </div>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                width="100%"
                height="100%"
                class="circle"
                viewBox="0 0 160 160"
              >
                <defs>
                  <linearGradient id="GradientColor">
                    <stop offset="0%" stop-color="#e91e63" />
                    <stop offset="100%" stop-color="#673ab7" />
                  </linearGradient>
                </defs>
                <circle
                  cx="-80"
                  cy="80"
                  r="61"
                  stroke-linecap="round"
                  :style="{
                    'stroke-dasharray': circlePerimeter,
                    '--percentage': dashOffset,
                    '--circlePerimeter': circlePerimeter,
                    '--time': `${computedPercentage * 40}ms`,
                  }"
                />
              </svg>
            </div>
            <div class="title">عدد الفيديوهات اللي شوفتها</div>
          </div>
          <div class="content-chart">
            <div class="result-percent">
              <div class="outer">
                <div class="inner">
                  <div class="percentage">
                    {{ analysis_data?.exam_progress?.total_exams_taken || 0 }}/
                    {{
                      analysis_data?.exam_progress
                        ?.total_exams_should_be_taken || 0
                    }}
                  </div>
                </div>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                width="100%"
                height="100%"
                class="circle"
                viewBox="0 0 160 160"
              >
                <defs>
                  <linearGradient id="GradientColor">
                    <stop offset="0%" stop-color="#e91e63" />
                    <stop offset="100%" stop-color="#673ab7" />
                  </linearGradient>
                </defs>
                <circle
                  cx="-80"
                  cy="80"
                  r="61"
                  stroke-linecap="round"
                  :style="{
                    'stroke-dasharray': circlePerimeter,
                    '--percentage': dashOffsetExams,
                    '--circlePerimeter': circlePerimeter,
                    '--time': `${computedPercentageExams * 40}ms`,
                  }"
                />
              </svg>
            </div>
            <div class="title">عدد الاختبارات اللي خلصتها</div>
          </div>
          <div class="content-chart">
            <div class="result-percent">
              <div class="outer">
                <div class="inner">
                  <div class="percentage">
                    {{
                      analysis_data?.exam_progress?.total_student_scores || 0
                    }}/
                    {{ analysis_data?.exam_progress?.total_exam_scores || 0 }}
                  </div>
                </div>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                width="100%"
                height="100%"
                class="circle"
                viewBox="0 0 160 160"
              >
                <defs>
                  <linearGradient id="GradientColor">
                    <stop offset="0%" stop-color="#e91e63" />
                    <stop offset="100%" stop-color="#673ab7" />
                  </linearGradient>
                </defs>
                <circle
                  cx="-80"
                  cy="80"
                  r="61"
                  stroke-linecap="round"
                  :style="{
                    'stroke-dasharray': circlePerimeter,
                    '--percentage': dashOffsetScores,
                    '--circlePerimeter': circlePerimeter,
                    '--time': `${computedPercentageScores * 40}ms`,
                  }"
                />
              </svg>
            </div>
            <div class="title">متوسط النتائج اللي جبتها</div>
          </div>
        </div>
      </template>
      <!-- <div class="chart-container">
        <canvas id="myChart"></canvas>
      </div> -->
    </div>
  </div>
</template>

<script>
import LoadingComponent from "@/components/Loading/LoadingComponent.vue";
import ErrorComponent from "@/components/Error/ErrorComponent.vue";
import config from "@/services/config";
import { FaVideo, PhFillTestTube, CaResult } from "@kalimahapps/vue-icons";
import axios from "axios";
import Chart from "chart.js/auto";
export default {
  name: "LevelIndicatorComponent",
  components: {
    LoadingComponent,
    ErrorComponent,
    FaVideo,
    PhFillTestTube,
    CaResult,
  },
  data() {
    return {
      isDark: localStorage.getItem("darkMode") === "true" ? true : false,
      is_loading: true,
      analysis_data: {},
      chartData: {
        labels: [
          "4/1/2024",
          "5/1/2024",
          "6/1/2024",
          "7/1/2024",
          "8/1/2024",
          "9/1/2024",
          "10/1/2024",
          "11/1/2024",
        ],
        datasets: [
          {
            label: "Percentage",
            data: [30, 60, 90, 70, 50, 80, 90, 60],
            fill: true,
            borderColor: "#48CFAD",
            pointBackgroundColor: "#48CFAD",
            tension: 0.4, // للتحكم في انحناء الخط
          },
        ],
      },
      chartOptions: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
            max: 100,
            ticks: {
              callback: function (value) {
                return value + "%";
              },
            },
          },
        },
      },
      percentage: 0,
    };
  },
  methods: {
    get_data() {
      axios
        .get(`${config.baseURL}analysis/profile-analysis`, {
          headers: {
            auth: this.$store.state.userToken,
          },
        })
        .then((res) => {
          // courses progress
          res.data.progress_in_all_courses.progress =
            res.data.progress_in_all_courses.total_lessons == 0
              ? 0
              : (
                  (res.data.progress_in_all_courses.watched_lessons /
                    res.data.progress_in_all_courses.total_lessons) *
                  100
                ).toFixed(2);

          // exams progress
          res.data.exam_progress.progress =
            res.data.exam_progress.total_exams_should_be_taken == 0
              ? 0
              : (
                  (res.data.exam_progress.total_exams_taken /
                    res.data.exam_progress.total_exams_should_be_taken) *
                  100
                ).toFixed(2);

          // average
          res.data.exam_progress.average =
            res.data.exam_progress.total_exam_scores == 0
              ? 0
              : (
                  (res.data.exam_progress.total_student_scores /
                    res.data.exam_progress.total_exam_scores) *
                  100
                ).toFixed(2);

          this.analysis_data = res.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
    // Create Chart Method
    createChart() {
      // Get the context of the canvas element we want to select
      const ctx = document.getElementById("myChart").getContext("2d");
      // Create a linear gradient
      const gradient = ctx.createLinearGradient(0, 0, 0, 400);
      gradient.addColorStop(0, "rgba(255, 255, 255, 0.8)");
      gradient.addColorStop(1, "rgba(42, 191, 122, 0.8)");
      ctx.fillStyle = gradient;
      const chartOptions = {
        responsive: true,
        scales: {
          y: {
            ticks: {
              color: this.isDark ? "#fff" : "#000",
            },
          },
          x: {
            ticks: {
              color: this.isDark ? "#fff" : "#000",
            },
          },
        },
      };
      // Create a chart
      new Chart(ctx, {
        type: "line",
        data: this.chartData,
        options: {
          ...this.chartOptions,
          ...chartOptions,
          plugins: {
            legend: {
              display: false,
            },
          },
          elements: {
            line: {
              backgroundColor: gradient,
            },
          },
        },
      });
    },
  },
  computed: {
    computedPercentage() {
      if (
        !this.analysis_data?.progress_in_all_courses ||
        this.analysis_data.progress_in_all_courses.total_lessons === 0
      ) {
        return 0;
      }
      return (
        (this.analysis_data.progress_in_all_courses.watched_lessons /
          this.analysis_data.progress_in_all_courses.total_lessons) *
        100
      ).toFixed(2);
    },
    computedPercentageExams() {
      if (
        !this.analysis_data?.exam_progress ||
        this.analysis_data?.exam_progress?.total_exams_should_be_taken === 0
      ) {
        return 0;
      }
      return (
        (this.analysis_data?.exam_progress?.total_exams_taken /
          this.analysis_data?.exam_progress?.total_exams_should_be_taken) *
        100
      ).toFixed(2);
    },
    computedPercentageScores() {
      if (
        !this.analysis_data?.exam_progress ||
        this.analysis_data?.exam_progress?.total_exam_scores === 0
      ) {
        return 0;
      }
      return (
        (this.analysis_data?.exam_progress?.total_student_scores /
          this.analysis_data?.exam_progress?.total_exam_scores) *
        100
      ).toFixed(2);
    },
    circlePerimeter() {
      return 2 * Math.PI * 61;
    },
    dashOffset() {
      return (
        this.circlePerimeter -
        (this.circlePerimeter * this.computedPercentage) / 100
      );
    },
    dashOffsetExams() {
      return (
        this.circlePerimeter -
        (this.circlePerimeter * this.computedPercentageExams) / 100
      );
    },
    dashOffsetScores() {
      return (
        this.circlePerimeter -
        (this.circlePerimeter * this.computedPercentageScores) / 100
      );
    },
  },
  created() {
    this.get_data();
  },
};
</script>

<style scoped>
.result-percent {
  width: 160px;
  height: 160px;
  position: relative;
  padding: 0.5rem;
}
.result-percent .outer {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  padding: 0.25rem;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.15),
    0px 0px 5px 1px rgba(255, 255, 255, 0.7);
  background-color: #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
body.dark .result-percent .outer {
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.15),
    0px 0px 5px 1px rgba(0, 0, 0, 0.3);
  background-color: #13323a;
}
.result-percent .inner {
  width: 75%;
  height: 75%;
  border-radius: 50%;
  box-shadow: inset 4px 4px 6px -1px rgba(0, 0, 0, 0.2),
    inset -4px -4px 6px -1px rgba(255, 255, 255, 0.7),
    -0.5px -0.5px 0px rgba(255, 255, 255, 1),
    0.5px 0.5px 0px rgba(0, 0, 0, 0.15), 0px 12px 10px -10px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}
body.dark .result-percent .inner {
  box-shadow: inset 4px 4px 6px -1px rgba(0, 0, 0, 0.2),
    inset -4px -4px 6px -1px rgba(0, 0, 0, 0.3),
    -0.5px -0.5px 0px rgba(0, 0, 0, 0.5), 0.5px 0.5px 0px rgba(0, 0, 0, 0.15),
    0px 12px 10px -10px rgba(0, 0, 0, 0.05);
  background-color: #1d4955;
}
.result-percent .percentage {
  font-size: 1.25rem;
  font-weight: 600;
  color: #000000;
}
body.dark .result-percent .percentage {
  color: #ffffff;
}
.result-percent circle {
  fill: none;
  stroke: #2abf7a;
  stroke-width: 1rem;
  stroke-dashoffset: 0;
  transform: rotate(-90deg);
  animation: anim var(--time) linear forwards;
}
.result-percent .circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@keyframes anim {
  0% {
    stroke-dashoffset: var(--circlePerimeter);
  }
  100% {
    stroke-dashoffset: var(--percentage);
  }
}

.courses-statistics-box-container {
  min-height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 50px;
  margin: 0 auto;
  box-shadow: 0 0 10px 0 #00000010;
  gap: 2rem;
  overflow: hidden;
}

.statistics-prog-container {
  position: relative;
  background-color: #d9d9d9;
  border-radius: 23px;
  width: 100%;
  height: 11px;
  overflow: hidden;
}

.statistics-prog {
  position: relative;
  display: block;
  width: 90%;
  height: 11px;
  border-radius: 23px;
  background-color: #2abf7a;
  opacity: 0;
  animation: progress 1.5s ease-in-out forwards;
}

/* Animation to show the progress bar */
@keyframes progress {
  0% {
    opacity: 0;
    width: 0;
  }

  100% {
    opacity: 1;
  }
}

.courses-statistics-box-item {
  width: 100%;
}

.title {
  font-size: 1.25rem;
  font-weight: 500;
  color: #000;
  margin-bottom: 1rem;
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-top: 0.5rem;
}

.video,
.test,
.average {
  font-size: 13px;
  font-weight: 500;
  color: #000;
}

.video svg,
.test svg {
  font-size: 1rem;
}

.video svg {
  color: #e71c39;
}

.test svg {
  color: #9747ff;
}

.average {
  background-color: #000;
  color: #fff;
  text-align: center;
  width: 55px;
  height: 20px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chart-container-percent {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  gap: 1rem;
  margin: 2rem 0;
}
.content-chart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.chart-container {
  width: 100%;
  margin-top: 1rem;
  overflow: auto;
}

.chart-container canvas {
  min-width: 850px;
  min-height: 350px;
  border-radius: 20px;
}

/* Dark Mode */
body.dark .courses-statistics-box-container {
  background-color: #1d4955;
}

body.dark .average,
body.dark .statistics-prog-container {
  background-color: #13323a;
}

body.dark .title,
body.dark .bottom span {
  color: #fff;
}

/*  Media queries */
@media screen and (max-width: 992px) {
  .courses-statistics-box-container {
    width: 100%;
  }

  .title {
    font-size: 1rem;
  }
}
@media screen and (max-width: 768px) {
  .chart-container-percent {
    flex-direction: column;
  }
}
</style>
