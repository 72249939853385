<template>
  <div class="level-indicator-view">
    <div class="header header-level-indicator-view mean-header">
      <h1>مؤشر المستوى</h1>
    </div>
    <LevelIndicatorComponent />
  </div>
</template>

<script>
import LevelIndicatorComponent from "../../components/Profile/LevelIndicator/LevelIndicatorComponent.vue";

export default {
  name: "LevelIndicatorView",
  components: {
    LevelIndicatorComponent,
  },
};
</script>

<style scoped>
.level-indicator-view {
  min-height: calc(100vh - 135px);
  background-color: #f9f9f9;
  padding: 4rem 0;
}
.level-indicator-view .header {
  text-align: center;
  position: relative;
  margin: 2rem 0 6rem 0;
}
.level-indicator-view .header::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #00000050;
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 0;
  transform: translate(0, -100%);
}
.level-indicator-view .header h1 {
  margin: 0 auto;
  text-align: center;
  border: 1px solid #00000050;
  color: #000;
  background-color: #f9f9f9;
  font-size: 1.25rem;
  font-weight: 500;
  width: fit-content;
  padding: 1rem 5.5rem;
  border-radius: 37px;
  position: relative;
  z-index: 1;
}

/* Dark mode */
body.dark .level-indicator-view {
  background-color: #13323a;
}
body.dark .level-indicator-view .header::before {
  background-color: #ffffff;
}
body.dark .level-indicator-view .header h1 {
  color: #fff;
  background-color: #1d4955;
  border: 1px solid #ffffff50;
}
/*  Media queries */
@media screen and (max-width: 992px) {
  .container-component {
    max-width: 100%;
    padding: 0;
  }
  .level-indicator-view .header h1 {
    padding: 1rem 3rem;
    font-size: 1rem;
  }
}
@media screen and (max-width: 768px) {
  .level-indicator-view .header h1 {
    padding: 1rem 1.5rem;
    font-size: 0.875rem;
  }
}
</style>
