<template>
  <div class="home">
    <BannerComponent />
    <AboutComponent />
    <AdvertComponent />
    <CoursesHomePageComponent />
    <!-- <CardComponent /> -->
    <!-- <FeatureComponent /> -->
    <!-- <div class="line"></div> -->
    <!-- <PhasesComponent /> -->
    <!-- <div class="line-2"></div> -->
    <!-- <SpecialWatermelonComponent /> -->
  </div>
</template>

<script>
// @ is an alias to /src
import {
  BannerComponent,
  AboutComponent,
  AdvertComponent,
  CoursesHomePageComponent,
  // CardComponent,
  // FeatureComponent,
  // PhasesComponent,
  // SpecialWatermelonComponent,
} from "@/components";
export default {
  name: "HomeView",
  components: {
    BannerComponent,
    AboutComponent,
    AdvertComponent,
    CoursesHomePageComponent,
    // CardComponent,
    // FeatureComponent,
    // PhasesComponent,
    // SpecialWatermelonComponent,
  },
};
</script>
<style scoped>
.home {
  /* height: 100%; */
  max-width: 100vw;
  overflow: hidden;
}
.line {
  /* Line 2 */

  /* position: absolute; */
  min-width: 100%;
  /* min-width: 100vw; */
  height: 0px;
  /* left: -1342px; */
  /* top: 2422px; */
  overflow: hidden;

  border: 1px solid rgba(0, 0, 0, 0.1);
}
.line-2 {
  /* Line 3 */

  /* position: absolute; */
  min-width: 100%;
  height: 0px;
  /* left: -1342px; */
  /* top: 3005px; */
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
