<template>
  <!-- Loading -->
  <LoadingComponent v-if="is_loading" />
  <!-- Error -->
  <ErrorComponent v-else-if="error && !is_loading" :error="error" />
  <!-- Data -->
  <div class="container" v-else>
    <div class="export-container">
      <button class="export-button" id="toEXCEL" @click="exportToExcel('views_details_table')" v-if="!is_loading && subscriptions.length > 0">
        تحميل ملف Exel
      </button>
    </div>
    <div class="table" id="customers_table">
      <div class="table__body">
        <table
        id="views_details_table"
        >
          <thead>
            <tr>
              <th>التسلسل</th>
              <th>اسم الطالب</th>
              <th>اسم الكورس</th>
              <th>حاله الكورس</th>
              <th>طريقة الاشتراك</th>
              <th>رقم الفاتورة</th>
              <th>تاريخ الاشتراك</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="!is_loading && subscriptions.length > 0">
              <tr v-for="(sub, index) in subscriptions" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ sub?.student__name }}</td>
                <td>{{ sub?.course__name }}</td>
                <td v-if="sub?.active" class="active">تم التفعيل</td>
                <td v-else class="not_active">لو يتم اتفعيل بعد</td>
                <td v-if="sub?.invoice_method == 'M'">يدوي</td>
                <td v-if="sub?.invoice_method == 'C'">كود</td>
                <td v-if="sub?.invoice_method == 'F'">فوري</td>
                <td>{{ sub?.invoice_sequence }}</td>
                <td>{{ getHumanDate(sub?.created) }}</td>
              </tr>
            </template>
            <!-- No Data -->
            <tr v-if="!is_loading && subscriptions.length == 0">
              <td colspan="12" class="no-data">لا يوجد اشتراكات حتي الان</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <PaginationBtns :next="hav_next" :prev="hav_prev" :link="'subscriptions'" />
  </div>
</template>

<script>
import axios from "axios";
import exportToExcel from "../../../services/csv";
import config from "@/services/config";
import LoadingComponent from "@/components/Loading/LoadingComponent.vue";
import ErrorComponent from "@/components/Error/ErrorComponent.vue";
import PaginationBtns from "@/components/PaginationBtns/PaginationBtns.vue";
import { watchEffect } from "vue";
export default {
  name: "SubscriptionsComponent",
  components: {
    LoadingComponent,
    ErrorComponent,
    PaginationBtns,
  },
  data() {
    return {
      subscriptions: [],
      is_loading: true,
      error: null,
      hav_next: false,
      hav_prev: false,
    };
  },
  created() {
    watchEffect(() => {
      this.get_course_subscriptions();
    });
  },
  methods: {
    exportToExcel(id) {
      exportToExcel(id, "الاشتراكات");
    },
    get_course_subscriptions() {
      axios
        .get(`${config.baseURL}student/course-subscription-list`, {
          headers: {
            auth: this.$store.state.userToken,
          },
        })
        .then((res) => {
          this.subscriptions = res.data.results;
          this.hav_next = res.data.next ? true : false;
          this.hav_prev = res.data.previous ? true : false;
        })
        .catch((err) => {
          this.error = err?.response?.status
            ? err.response.status
            : err.message;
          console.log(err.message);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
    getHumanDate(d) {
      const date = new Date(d);
      const options = {
        weekday: "long",
        year: "numeric",
        month: "short",
        day: "numeric",
      };
      return date.toLocaleDateString("ar", options);
    },
  },
};
</script>

<style scoped>
.export-container {
  text-align: center;
  margin-bottom: 2rem;
}

.export-button {
  background-color: #fff;
  color: #000;
  box-shadow: 0px 0px 2px 0px #000000;
  padding: 1rem 2rem;
  border-radius: 30px;
  cursor: pointer;
  border: none;
  outline: none;
  font-size: 1rem;
  transition: all 0.3s;
}

.export-button:hover {
  background-color: #038c4c;
  color: #fff;
}

.table > :not(caption) > * > * {
  background-color: transparent;
}

.table {
  position: relative;
  width: 100%;
  height: 75vh;
  background-color: #fff;
  box-shadow: 0 0.4rem 0.8rem #0005;
  border-radius: 1.25rem;
  overflow: hidden;
  padding: 2rem;
  direction: ltr;
}

.table__body {
  background-color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 1.25rem;
  padding: 0 2rem 2rem 2rem;
  overflow: auto;
  overflow: overlay;
}

.table__body::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.table__body::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background-color: #c3c3c3;
  border: 1px solid #c3c3c3;
}

.table__body::-webkit-scrollbar-track {
  background-color: #eeeeee;
}

table {
  width: 100%;
  direction: rtl;
}

table,
th,
td {
  border-collapse: collapse;
  padding: 1rem 0.5rem;
  text-align: center;
  min-width: 190px;
  min-height: 31px;
}

thead th {
  position: sticky;
  top: 0;
  /* left: 0; */
  z-index: 1;
  background-color: #fff;
  color: #000;
  border: none;
  border-bottom: 0.1rem solid #00000020;
}

tbody tr {
  background-color: #fff;
  border: none;
  border-bottom: 0.1rem solid #00000010;
  padding: 0.8rem 0;
  margin-bottom: 0.8rem !important;
}

th {
  color: #000;
  font-weight: 700;
}

td {
  color: #000000;
  font-weight: 500;
}

td.active,
td.not_active {
  padding: 0.3rem;
  border-radius: 0.5rem;
}

/* td.active {
  background-color: #2ABF7A;
}

td.not_active {
  background-color: #751630;
} */

/* Dark Mode */
body.dark th {
  color: #fff;
}

body.dark .export-button {
  background-color: #1d4955;
  color: #fff;
}

body.dark .export-button:hover {
  background-color: #038c4c;
}

body.dark .table__body::-webkit-scrollbar-thumb {
  background-color: #235562;
  border: 1px solid #13323a;
}

body.dark .table__body::-webkit-scrollbar-track {
  background-color: #13323a;
}

body.dark tbody tr,
body.dark .table__body,
body.dark .table {
  background-color: #1d4955;
}

body.dark thead th {
  background-color: #1d4955;
  color: #fff;
  border: none;
  border-bottom: 0.1rem solid #ffffff20;
}

body.dark td {
  color: #ffffff;
}
.no-data {
  color: #000 !important;
  font-size: 1.5rem !important;
  font-weight: 500 !important;
}
body.dark .no-data {
  color: #fff !important;
}

/*  Media queries */
@media screen and (max-width: 992px) {
  .export-button {
    padding: 0.75rem 1.5rem;
    font-size: 0.9rem;
  }

  .table {
    padding: 1rem;
  }

  .table__body {
    padding: 0 1rem 1rem 1rem;
  }

  th,
  td {
    font-size: 0.9rem;
  }
}
</style>
