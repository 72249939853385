<template>
  <div class="notifications-view">
    <div class="header header-notifications-view mean-header">
      <h1>الإشعارات</h1>
    </div>
    <!-- Loading -->
    <LoadingComponent v-if="is_loading" />
    <!-- Error -->
    <ErrorComponent v-else-if="error && !is_loading" :error="error" />
    <!-- Data -->
    <NotificationsComponent
      v-else
      :is_loading="is_loading"
      :notifications="notifications"
    />
  </div>
</template>

<script>
import LoadingComponent from "@/components/Loading/LoadingComponent.vue";
import ErrorComponent from "@/components/Error/ErrorComponent.vue";
import NotificationsComponent from "../../components/Profile/Notifications/NotificationsComponent.vue";
import axios from "axios";
import config from "@/services/config";

export default {
  name: "NotificationsView",
  components: {
    LoadingComponent,
    ErrorComponent,
    NotificationsComponent,
  },
  data() {
    return {
      is_loading: true,
      error: null,
      notifications: [],
    };
  },
  methods: {
    getNotifications() {
      axios
        .get(`${config.baseURL}student/notification-list`, {
          headers: {
            auth: this.$store.state.userToken,
          },
        })
        .then((res) => {
          this.notifications = res.data.results;
        })
        .then(() => {
          this.readNotification();
        })
        .catch((err) => {
          this.error = err?.response?.status
            ? err.response.status
            : err.message;
          console.log(err.message);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
    readNotification() {
      axios
        .patch(`${config.baseURL}notification/notification-read-update`, null, {
          headers: {
            auth: this.$store.state.userToken,
          },
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
  },
  created() {
    this.getNotifications();
  },
};
</script>

<style scoped>
.notifications-view {
  min-height: calc(100vh - 135px);
  background-color: #f9f9f9;
  padding: 4rem 0;
}
.notifications-view .header {
  text-align: center;
  position: relative;
  margin: 2rem 0 6rem 0;
}
.notifications-view .header::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #00000050;
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 0;
  transform: translate(0, -100%);
}
.notifications-view .header h1 {
  margin: 0 auto;
  text-align: center;
  border: 1px solid #00000050;
  color: #000;
  background-color: #f9f9f9;
  font-size: 1.25rem;
  font-weight: 500;
  width: fit-content;
  padding: 1rem 5.5rem;
  border-radius: 37px;
  position: relative;
  z-index: 1;
}

/* Dark mode */
body.dark .notifications-view {
  background-color: #13323a;
}
body.dark .notifications-view .header::before {
  background-color: #ffffff;
}
body.dark .notifications-view .header h1 {
  color: #fff;
  background-color: #1d4955;
  border: 1px solid #ffffff50;
}
/*  Media queries */
@media screen and (max-width: 992px) {
  .container-component {
    max-width: 100%;
    padding: 0;
  }
  .notifications-view .header h1 {
    padding: 1rem 3rem;
    font-size: 1rem;
  }
}
@media screen and (max-width: 768px) {
  .notifications-view .header h1 {
    padding: 1rem 1.5rem;
    font-size: 0.875rem;
  }
}
</style>
