<template>
  <div class="about">
    <div
      data-aos="fade-left"
      data-aos-duration="1000"
      class="about-left aos-init aos-animate"
    >
      <img
        class="banner"
        src=""
        alt=""
        srcset="@/assets/mohamedAbdelmaged/about-animation.png"
      /><img alt="" srcset="@/assets/mohamedAbdelmaged/abdelmagid.png" />
    </div>
    <div
      data-aos="fade-right"
      data-aos-duration="1000"
      class="about-right aos-init aos-animate"
    >
      <h1>أقـوي منصة تـعليمية في <span class="marker">مـادة الفيزياء</span></h1>
      <p>
        اهلا بيك في منصتنا التعليمية, احنا هنا عشان نساعدك توصل لكل المعلومات
        الي&nbsp;محتاجها&nbsp;بسهوله
      </p>
      <div class="prp">
        <svg
          stroke="currentColor"
          fill="currentColor"
          stroke-width="0"
          viewBox="0 0 512 512"
          class="icon"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M256 48C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48zm106.5 150.5L228.8 332.8h-.1c-1.7 1.7-6.3 5.5-11.6 5.5-3.8 0-8.1-2.1-11.7-5.7l-56-56c-1.6-1.6-1.6-4.1 0-5.7l17.8-17.8c.8-.8 1.8-1.2 2.8-1.2 1 0 2 .4 2.8 1.2l44.4 44.4 122-122.9c.8-.8 1.8-1.2 2.8-1.2 1.1 0 2.1.4 2.8 1.2l17.5 18.1c1.8 1.7 1.8 4.2.2 5.8z"
          ></path>
        </svg>
        <h4>تقييم مستوي الطالب شهريا</h4>
      </div>
      <div class="prp">
        <svg
          stroke="currentColor"
          fill="currentColor"
          stroke-width="0"
          viewBox="0 0 512 512"
          class="icon"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M256 48C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48zm106.5 150.5L228.8 332.8h-.1c-1.7 1.7-6.3 5.5-11.6 5.5-3.8 0-8.1-2.1-11.7-5.7l-56-56c-1.6-1.6-1.6-4.1 0-5.7l17.8-17.8c.8-.8 1.8-1.2 2.8-1.2 1 0 2 .4 2.8 1.2l44.4 44.4 122-122.9c.8-.8 1.8-1.2 2.8-1.2 1.1 0 2.1.4 2.8 1.2l17.5 18.1c1.8 1.7 1.8 4.2.2 5.8z"
          ></path>
        </svg>
        <h4>مراجعات وامتحانات شاملة</h4>
      </div>
      <div class="prp">
        <svg
          stroke="currentColor"
          fill="currentColor"
          stroke-width="0"
          viewBox="0 0 512 512"
          class="icon"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M256 48C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48zm106.5 150.5L228.8 332.8h-.1c-1.7 1.7-6.3 5.5-11.6 5.5-3.8 0-8.1-2.1-11.7-5.7l-56-56c-1.6-1.6-1.6-4.1 0-5.7l17.8-17.8c.8-.8 1.8-1.2 2.8-1.2 1 0 2 .4 2.8 1.2l44.4 44.4 122-122.9c.8-.8 1.8-1.2 2.8-1.2 1.1 0 2.1.4 2.8 1.2l17.5 18.1c1.8 1.7 1.8 4.2.2 5.8z"
          ></path>
        </svg>
        <h4>توفير اكبر قدر من الأسئلة</h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutComponent",
  data() {
    return {};
  },
};
</script>

<style scoped>
.about {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 0 0 0;
}
.about .about-left {
  position: relative;
  width: 50%;
}
.about .about-left img {
  width: 100%;
}
.about .about-right {
  width: 50%;
  padding: 0 20px;
}
.about .about-right h1 {
  font-size: 2.5rem;
  font-weight: 900;
  line-height: 1.9;
  color: #000000;
}
.about-right .marker {
  color: #7a5af9;
}
.about .about-right p {
  line-height: 1.9;
  color: #1e1e1e;
}
.about .about-right .prp {
  align-items: center;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 1px 2px 0 #3c40434d, 0 1px 3px 1px #3c404326;
  display: flex;
  gap: 5px;
  justify-content: center;
  margin-top: 10px;
  padding: 10px;
  width: max-content;
}
.about .about-right .prp .icon {
  color: #1090f3;
  font-size: 25px;
}
.about .about-right .prp h4 {
  color: #000000;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
}
.about .about-left .banner {
  animation: banner 5s 0s infinite;
  position: absolute;
  right: 20px;
  top: 10px;
  width: 25%;
  z-index: -1 !important;
}
@keyframes banner {
  0% {
    right: 10px;
  }
  50% {
    right: 60px;
  }
  100% {
    right: 10px;
  }
}

body.dark .about .about-right .prp {
  background-color: #1e1e1e;
  color: #ffffff;
}
body.dark .about .about-right .prp h4,
body.dark .about .about-right h1 {
  color: #ffffff;
}
body.dark .about .about-right p {
  color: #e1e1e1;
}

@media (max-width: 768px) {
  .about {
    flex-direction: column;
  }
  .about .about-left {
    width: 100%;
  }
  .about .about-right {
    width: 100%;
    padding: 0 20px 0 0.5rem;
  }
  .about .about-left img {
    width: 100%;
  }
}
@media (max-width: 576px) {
  .about .about-right h1 {
    font-size: 2rem;
  }
  .about .about-right p {
    font-size: 0.9rem;
  }
  .about .about-right.prp .icon {
    font-size: 20px;
  }
  .about .about-right .prp h4 {
    font-size: 1rem;
  }
}
</style>
