<template >
    <img  class="w-100 h-100" style="object-fit: contain;" src="@/assets/mohamedAbdelmaged/logo.png" alt="">
</template>
<script>
export default {
    name: 'LogoComponent'
}
</script>
<style scoped>
    .logo {
        width: 80px;
    }

</style>