<template>
  <div class="ms-3 auth-btns-container" v-if="!$store.state.isAuthenticated">
    <router-link
      class="login-btn auth-btn desktop-only"
      :to="{ name: 'login' }"
    >
      <BxLogInCircle class="ms-2" />
      تسجيل الدخول
    </router-link>
    <router-link
      class="register-btn auth-btn desktop-only"
      :to="{ name: 'register' }"
    >
      <CdAccount class="ms-2" />
      أنشئ حسابك
    </router-link>
    <router-link class="login-btn auth-btn mobile-only" :to="{ name: 'login' }">
      <BxLogInCircle class="ms-2" />
      تسجيل
    </router-link>
  </div>
  <div class="ms-3 auth-btns-container" v-else>
    <button @click="logout" class="logout-btn auth-btn desktop-only">
      <MiLogoutHalfCircle class="ms-2" />
      تسجيل خروج
    </button>
    <button @click="logout" class="logout-btn auth-btn text-center mobile-only">
      <MiLogoutHalfCircle />
      خروج
    </button>
  </div>
</template>
<script>
import {
  MiLogoutHalfCircle,
  BxLogInCircle,
  CdAccount,
} from "@kalimahapps/vue-icons";
import Cookies from "js-cookie";
import Swal from "sweetalert2";

export default {
  name: "AuthBtnsComponent",
  components: {
    MiLogoutHalfCircle,
    BxLogInCircle,
    CdAccount,
  },
  methods: {
    logout() {
      Swal.fire({
        title: " متاكد من تسجيل الخروج من المنصه ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#57F2AA",
        cancelButtonColor: "#e74c3c",
        confirmButtonText: "تسجيل خروج",
        cancelButtonText: " الغاء ",
      }).then((result) => {
        if (result.isConfirmed) {
          Cookies.remove("user_data");
          Cookies.remove("user_token");
          this.$store.commit("LOGOUT_USER");
          this.$router.push("/login");
          Swal.fire({
            toast: true,
            title: " تم تسجيل الخروج بنجاح ",
            icon: "success",
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            position: "top-end",
          });
        }
      });
    },
  },
};
</script>
<style scoped>
.auth-btns-container {
  background-color: rgba(241, 241, 241, 1);
  border-radius: 12px;
  padding: 1.2rem 0.5rem;
}
.auth-btn {
  all: unset;
  padding: 0.5rem 1rem;
  border-radius: 9.53px;
  /* border: 1.36px solid #1e1e1e; */
  font-weight: bold;
  cursor: pointer;
}
.login-btn,
.logout-btn {
  background-color: rgba(255, 255, 255, 1);
  color: #1e1e1e;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.register-btn {
  background-color: rgba(241, 241, 241, 1);
  color: rgba(30, 30, 30, 0.7);
  margin-right: 1rem;
}
.mobile-only {
  display: none;
}

/* dark */

body.dark .auth-btns-container,
body.dark .register-btn {
  background-color: #1e1e1e;
  border: 1px solid #333;
}

body.dark .register-btn {
  color: rgba(241, 241, 241, 1);
}

body.dark .login-btn,
body.dark .logout-btn {
  background-color: #333;
  color: rgba(241, 241, 241, 1);
}

@media (max-width: 767px) {
  .auth-btns-container {
    padding: 0.3rem;
  }
  .desktop-only {
    display: none;
  }
  .mobile-only {
    display: flex !important;
    align-items: center;
    padding: 0.5rem;
  }
}
@media (max-width: 575px) {
  .mobile-only {
    font-size: 0.75rem;
  }
}
</style>
