<template>
  <div
    class="exam-student-answer-item-container"
    :class="{ right: data?.is_correct, wrong: !data?.is_correct }"
  >
    <div class="con">
      <div class="number-question">
        <span class="question-icon"><McQuestionFill /></span>
        <span class="question-number">السؤال رقم {{ number_question }}</span>
        <span class="question-icon-right" v-if="data?.is_correct"
          ><BsCheckCircleFill
        /></span>
        <span class="question-icon-wrong" v-else><PhFillXCircle /></span>
      </div>
      <div class="question">
        <p class="question-text m-0">
          {{ data?.question_text }}
        </p>
      </div>
      <div class="separator"></div>
      <div class="answer">
        <p class="answer-right">
          <span class="right" v-if="data?.is_solved">
            {{ data?.selected_answer }}.
          </span>
          <span class="right" v-else>لم يتم الإجابة.</span>
          <BsCheckCircleFill class="right-icon" v-if="data?.is_correct" />
          <PhFillXCircle class="wrong-icon" v-else />
        </p>
        <CoArrowLeft class="arrow" v-if="!data?.is_correct" />
        <p class="answer-wrong" v-if="!data?.is_correct">
          <!-- <span class="wrong"> {{ data?.correct_answers[0]?.text }}. </span> -->
          <span class="wrong"> {{ data?.correct_answers?.text }}. </span>
          <BsCheckCircleFill class="right-icon" v-if="!data?.is_correct" />
          <PhFillXCircle class="wrong-icon" v-else />
        </p>
      </div>
    </div>
    <img
      v-if="data?.question_image"
      :src="data?.question_image"
      :alt="data?.question_text"
      class="question-image"
      @click="imageModal(data?.question_image, data?.question_text)"
    />
  </div>
</template>

<script>
import {
  McQuestionFill,
  BsCheckCircleFill,
  PhFillXCircle,
  CoArrowLeft,
} from "@kalimahapps/vue-icons";
import Swal from "sweetalert2";
export default {
  name: "ExamStudentAnswerItemComponent",
  components: {
    McQuestionFill,
    BsCheckCircleFill,
    PhFillXCircle,
    CoArrowLeft,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    number_question: {
      type: Number,
      required: true,
    },
  },
  // mounted() {
  //   console.log(this.data);
  // },

  methods: {
    imageModal(image, text) {
      Swal.fire({
        imageUrl: image,
        imageHeight: "auto",
        padding: "1rem",
        imageAlt: text,
        showCancelButton: false,
        showConfirmButton: false,
        customClass: "image-modal-question",
        // make image-modal-question is overflow is auto
        // to make the image scrollable
        scrollbarPadding: false,
      });
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
}
.exam-student-answer-item-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  padding: 0.75rem 1.5rem;
  border-radius: 2rem;
}
.exam-student-answer-item-container .con {
  display: flex;
  flex-direction: column;
}
.exam-student-answer-item-container.right {
  border: 1px solid #31d287;
  background-color: #e7fff4;
}
.exam-student-answer-item-container.wrong {
  border: 1px solid #e71c39;
  background-color: #ffe7e7;
}
.exam-student-answer-item-container.right .number-question {
  background-color: #038c4c;
}
.exam-student-answer-item-container.wrong .number-question {
  background-color: #e71c39;
}
body.dark .exam-student-answer-item-container.right .number-question {
  background-color: #04a55a;
}
body.dark .exam-student-answer-item-container.wrong .number-question {
  background-color: #e9334e;
}
.number-question {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  width: fit-content;
  margin-bottom: 1rem;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  font-size: 1.25rem;
  font-weight: 500;
}
.number-question svg {
  font-size: 1.5rem;
}
.number-question .question-icon {
  color: #fff8f8;
}
.number-question .question-icon-right {
  color: #00ff88;
}
.number-question .question-icon-wrong {
  color: #ffffff;
}
.question {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1rem;
  font-weight: 600;
  color: #000000;
}
body.dark .question {
  color: #ffffff;
}
.separator {
  border: 1px solid #00000080;
  height: 2rem;
  width: 1px;
  margin: 1rem 0;
}
body.dark .separator {
  border: 1px solid #ffffff80;
}
.answer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}
.answer-right,
.answer-wrong {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.arrow {
  color: #000000;
}
body.dark .arrow {
  color: #ffffff;
}
.answer-right .right,
.answer-wrong .wrong {
  font-size: 1rem;
  font-weight: 600;
}
.answer-right .right {
  color: #0278ff;
}
.answer-wrong .wrong {
  color: #e71c39;
}
body.dark .answer-right .right {
  color: #99c9ff;
}
body.dark .answer-wrong .wrong {
  color: #ffffff;
}
.arrow,
.right-icon,
.wrong-icon {
  font-size: 1.5rem;
}
.right-icon {
  color: #2abf7a;
}
.wrong-icon {
  color: #e71c39;
}
.question-image {
  width: 50%;
  height: 150px;
  object-fit: cover;
  border-radius: 1rem;
  cursor: pointer;
}
body.dark .exam-student-answer-item-container.right {
  border: 1px solid #46d693;
  background-color: #115e59;
}
body.dark .exam-student-answer-item-container.wrong {
  border: 1px solid #e9334e;
  background-color: #751630;
}
body.dark .right-icon {
  color: #31d287;
}
body.dark .wrong-icon {
  color: #e9334e;
}
@media (max-width: 768px) {
  .exam-student-answer-item-container {
    flex-direction: column;
  }
  .exam-student-answer-item-container .con {
    width: 100%;
  }
  .exam-student-answer-item-container .question-image {
    width: 100%;
  }
  .number-question {
    font-size: 1rem;
    font-weight: 500;
  }
  .question-image {
    margin-top: 1rem;
  }
}
</style>
